import React, { Component } from "react";
import Helmet from "react-helmet";
import "../../assets/sass/styles.sass";
import config from "../../../config";
import NavBar from "../NavBar";
import Footer from "../Footer";
import GlobalStyle from "../global-style";
import Banner from "../Banner/Banner";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = { isActive: false };
    this.toggleNavbar = this.toggleNavbar.bind(this);
  }

  toggleNavbar() {
    this.setState({ isActive: !this.state.isActive });
  }

  componentDidMount() {
    try {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      window.gtag = gtag;
      gtag("js", new Date());
      gtag("config", "AW-863279616");
      console.log("Setting up conversion tracking");
    } catch (e) {
      console.log("error loading conversion code", e);
    }
  }

  render() {
    return (
      <>
        <GlobalStyle />
        <Helmet>
          <title>{config.siteTitle}</title>
          <meta name="description" content={config.siteDescription} />
        </Helmet>
        <Banner css={{}} showBanner={true}>
          <a
            href="https://courses.iteachrecruiters.com/p/web-development-and-software-engineering-basics-for-tech-recruiters"
            target="_blank"
            rel="noopener noreferrer"
          >
            👋 Check out How To Speak Software Engineering Jargon for Recruiters -
            start training your team today! ✨$299 a seat with bulk pricing
            available. 
          </a>
        </Banner>
        <NavBar
          isActive={this.state.isActive}
          toggleNavbar={() => this.toggleNavbar()}
        />
        <>{this.props.children}</>
        <Footer />
      </>
    );
  }
}

export default Layout;
