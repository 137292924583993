module.exports = {
  siteTitle: 'ITeach Recruiters - An Online Course for tech recruiters', // Site title.
  siteTitleAlt: 'ITeach Recruiters - An Online Course for tech recruiters', // Alternative site title for SEO.
  siteLogo: '/icons/icon-512x512.png', // Logo used for SEO and manifest.
  // siteUrl: `https://www.iteachrecruiters.com`,
  siteUrl: 'https://www.iteachrecruiters.com',
  pathPrefix: '/', // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.
  siteDescription:
    'An Online Course designed to train tech recruiters in tech topics', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml',
  siteFBAppID: '', // FB Application ID for using app insights
  googleTagManagerID: '', // GTM tracking ID.
  disqusShortname: 'iteach-recruiters-blog', // Disqus shortname.
  userName: 'Aaron Decker',
  userTwitter: 'dynamicdoublea',
  userLocation: 'Minneapolis, MN',
  userDescription: '',
  copyright: 'Copyright © NanoDolphin Labs LLC 2023. All Rights Reserved.', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#00d1b2', // Used for setting manifest and progress theme colors.
  backgroundColor: '#ffffff', // Used for setting manifest background color.
}
